import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const CsvGraphFunc = ({ inputData }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!inputData || !Array.isArray(inputData.history)) {
      console.error('Invalid input data provided to CSVGraphFunc:', inputData);
      setError('Invalid or missing history data');
      return;
    }

    try {
      // Transform the history array into the format required for the graph
      const transformedData = inputData.history.map((row) => ({
        Date: row.Date, // Use the Date field directly
        Open: parseFloat(row.Open), // Ensure Open is a float
      }));

      console.log('Transformed history data:', transformedData);
      setData(transformedData);
    } catch (err) {
      console.error('Error transforming history data:', err);
      setError('Failed to process history data.');
    }
  }, [inputData]);

  return (
    <div className="csv-graph-container">
      <div className="csv-graph-inner">
        <h2>Historical Stock Data Graph</h2>

        {error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : data.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Date" />
              <YAxis /> {/* Default Y-axis */}
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Open" stroke="#8884d8" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <p>Loading data...</p>
        )}
      </div>
    </div>
  );
};

export default CsvGraphFunc;
