import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import StockInputForm from './prompts';
import FutureStockGraph from './FutureStockGraph';
import CsvGraphFunc from './CurrentStockGraph'; // Updated import for historical data

function App() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [predictions, setPredictions] = useState(null);

  const handleFormSubmit = async (formValues) => {
    setLoading(true);
    setError(null);
    setPredictions(null);

    try {
      const response = await fetch('https://stock-predictor-backend-code.onrender.com/predict', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formValues),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to fetch predictions from server');
      }

      const data = await response.json();
      console.log("Received predictions:", data);
      setPredictions(data);
    } catch (err) {
      console.error('Error fetching predictions:', err);
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Router>
      <div className="App">
        <Navbar />
        <h1 className="main-title">Stock Market Prediction Website</h1>
        <div className="content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <StockInputForm onSubmit={handleFormSubmit} />
                  {loading && <p>Loading predictions...</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {predictions ? (
                    <div>
                      <h2>Stock Data Visualizations</h2>
                      {/* Historical Stock Data Chart */}
                      <CsvGraphFunc inputData={predictions} />
                      {/* Future Predictions Chart */}
                      <FutureStockGraph inputData={predictions} />
                    </div>
                  ) : (
                    !loading && <p>Please submit the form to view predictions.</p>
                  )}
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
