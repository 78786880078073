import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const FutureStockGraph = ({ inputData }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!inputData || !Array.isArray(inputData.predictions)) {
      console.error('Invalid input data provided to FutureStockGraph:', inputData);
      setError('Invalid or missing prediction data');
      return;
    }

    try {
      // Transform the predictions array into the format required for the graph
      const transformedData = inputData.predictions.map((row) => ({
        Date: row.Date, // Use the Date field directly
        Predicted_Open: parseFloat(row.Predicted_Open), // Ensure Predicted_Open is a float
      }));

      console.log('Transformed predictions data:', transformedData);
      setData(transformedData);
    } catch (err) {
      console.error('Error transforming prediction data:', err);
      setError('Failed to process prediction data.');
    }
  }, [inputData]);

  return (
    <div className="csv-graph-container">
      <div className="csv-graph-inner">
        <h2>Future Stock Prediction Graph</h2>

        {error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : data.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Date" />
              <YAxis domain={[150, 180]} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Predicted_Open" stroke="#8884d8" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <p>Loading data...</p>
        )}
      </div>
    </div>
  );
};

export default FutureStockGraph;

