import React, { useState } from 'react';

const StockInputForm = ({ onSubmit }) => {
  const [stockTicker, setStockTicker] = useState('');
  const [timePeriod, setTimePeriod] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!stockTicker || !timePeriod || isNaN(timePeriod) || Number(timePeriod) <= 0) {
      alert("Please enter a valid stock ticker and a positive time period.");
      return;
    }

    const payload = {
      stockTicker: stockTicker.toUpperCase(),
      timePeriod: Number(timePeriod),
    };

    console.log('Payload being sent to App.js:', payload);
    onSubmit(payload);
  };

  return (
    <div className="stock-input-form">
      <h2>Enter Stock Information</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Enter Stock:</label>
          <input
            type="text"
            value={stockTicker}
            onChange={(e) => setStockTicker(e.target.value)}
            placeholder="e.g., AAPL"
            required
          />
        </div>
        <div>
          <label>Time Period (in days):</label>
          <input
            type="number"
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            placeholder="e.g., 30"
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default StockInputForm;
